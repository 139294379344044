var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"col-12",attrs:{"headers":_vm.header,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticStyle:{"font-weight":"bold","font-size":"25px"}},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer')],1),_c('v-container',[_c('v-text-field',{staticStyle:{"width":"500px"},attrs:{"append-icon":"fa-search","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":_vm.UpdateColor(item.estado)},on:{"click":function($event){return _vm.$emit('updateEstado',item)}}},[_vm._v(_vm._s(_vm.UpdateTexto(item.estado)))])]}},{key:"item.anio",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.agregarAnio(item)}}},[_vm._v("fa-plus")])]}},{key:"item.lote",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.agregarLote(item)}}},[_vm._v("fa-plus")])]}},{key:"item.detalle",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.verDetalle(item)}}},[_vm._v("fa-info-circle")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.editarHacienda(item)}}},[_vm._v("fa-edit")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }