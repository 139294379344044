











































import Component from "vue-class-component";
import Hacienda from "../entidades/Plantacion/Hacienda";
import Vue from "vue";
import Tabla from "../components/Maestro/Hacienda/Tabla.vue";
import Agregar from "../components/Maestro/Hacienda/Agregar.vue";
import AgregarAnioCultivo from "@/components/Maestro/Hacienda/AgregarItem.vue";
import Formulario from "../components/Formulario.vue";
import HaciendaItem from "@/entidades/Plantacion/HaciendaItem";
import { Mutation } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";
import { union } from "lodash";
import ResponseGenerico from "@/entidades/ResponseGenerico";

@Component({
  components: {
    Tabla,
    Formulario,
    Agregar,
    AgregarAnioCultivo,
    AgregarLote: () =>
      import("@/components/Maestro/Hacienda/AgregarLoteHacienda.vue"),
  },
})
export default class extends Vue {
  //Propiedades
  @Mutation("mostrarLoading", { namespace: "sistemaModule" })
  mostrarLoading!: Function;
  @Mutation("mostarAlert", { namespace: "sistemaModule" })
  mostarAlert!: Function;

  message?: String = "";
  notificacion?: Boolean = false;
  listado?: Array<HaciendaItem> = [];
  header: { text: string; sortable: boolean; value: string }[] = [
    { text: "Descripcion", sortable: true, value: "descripcion" },
    { text: "Año de Cultivo", sortable: true, value: "anioCultivo" },
    { text: "Orden Interna", sortable: true, value: "ordenInterna" },
    { text: "Editar", value: "action", sortable: false },
    { text: "Estado", sortable: true, value: "estado" },
  ];
  hacienda: Hacienda | undefined = new Hacienda();
  haciendaItem: HaciendaItem | undefined = new HaciendaItem();
  haciendas: Array<Hacienda> = [];

  //releacionado al agregar el modo hacienda item
  modalHaciendaItem: boolean = false;
  modalHaciendaItemName: string = "";
  //Agregar una hacienda
  modalHaciendaName: string = "";
  modalHacienda: boolean = false;
  //todos los metods

  async Obtener() {
    //Consultamos al API para traer todos nuestros HaciendaItems(Anios de Cultivo)
    try {
      this.mostrarLoading(new Loading(true, "Obteniendo Registros"));
      var rsp = await HaciendaItem.ObtenerHaciendaItem();
      if (rsp.isSuccess) {
        this.listado = rsp.isResult;
      } else {
        this.notificacion = true;
        this.message = rsp.isMessage;
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  async agregarItemHacienda(haciendaItem: HaciendaItem) {
    console.log(haciendaItem);
    //Metodo para agregar un HaciendaItem
    if (Number(haciendaItem.ordenInterna) == 0 || null) {
      this.mostarAlert(
        new Alerta("Debe ingresar una Orden Interna", true, "danger", 2000)
      );
      return;
    }
    try {
      this.mostrarLoading(new Loading(true, "Agregando"));
      let response = new ResponseGenerico(false, "");

      if (haciendaItem != undefined) {
        console.log("entramos al if de la validacion");
        if (haciendaItem.haciendaItemId! > 0) {
          console.log("entramos al if de editar item");
          response = await HaciendaItem.EditarHaciendaItem(haciendaItem);
        } else {
          console.log("entramos al else para guardar");
          response = await HaciendaItem.GuardarHaciendaItem(haciendaItem);
        }
      }

      if (response.isSuccess == true) {
        this.mostarAlert(new Alerta(response.isMessage, true, "primary", 2000));
        this.modalHaciendaItem = false;
        this.modalHaciendaItemName = "";
        this.hacienda = undefined;
        await this.Obtener();
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
      }
    } catch (error) {
      console.log(error);
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  async updateEstadoDb(hacienda: Hacienda) {
    //Metodo para activar o desactivar un HaciendaItem
    try {
      this.mostrarLoading(new Loading(true, "Actualizando estado"));
      //@ts-ignore
      var response = await HaciendaItem.EditarHaciendaItemEstado(hacienda);
      if (response.isSuccess == true) {
        this.mostarAlert(new Alerta(response.isMessage, true, "primary", 2000));
        await this.Obtener();
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  async abrirModalAnioCultivo() {
    try {
      this.mostrarLoading(new Loading(true, "Espee por favor..."));
      this.haciendaItem = undefined;
      this.haciendas = (await Hacienda.Obtener()).isResult;
      this.hacienda = undefined;
      this.modalHaciendaItemName = "AgregarAnioCultivo";
      this.modalHaciendaItem = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }
  async editarHaciendaModal(haciendaItem: HaciendaItem) {
    //Metodo para editar un HaciendaItem
    try {
      this.haciendas = (await Hacienda.Obtener()).isResult;
      this.haciendaItem = haciendaItem;
      this.hacienda = undefined;
      this.modalHaciendaItemName = "AgregarAnioCultivo";
      this.modalHaciendaItem = true;
      //console.log(this.haciendaItem);
    } catch (error) {
      console.log(error);
    }
  }

  abrilModalHacienda() {
    this.hacienda = new Hacienda();
    this.modalHaciendaName = "Agregar";
    this.modalHacienda = true;
  }
  cerrarModalHacienda() {
    this.modalHacienda = false;
    this.modalHaciendaName = "";
    this.hacienda = undefined;
  }

  closeModalHaciendaItem() {
    this.modalHaciendaItem = false;
    this.modalHaciendaItemName = "";
    this.hacienda = undefined;
  }
  //ciclo de vida
  mounted() {
    this.Obtener();
  }
}
