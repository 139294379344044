































































import Hacienda from '@/entidades/Plantacion/Hacienda';
import Vue from 'vue'
//import Component from 'vue-class-component';
import {  Prop ,Component } from 'vue-property-decorator'
@Component
export default class extends Vue{

    //Seccion de los prop
    @Prop({type:Array,required:true})
    header!:[];

    @Prop({type:Array,required:true})
    items!:[];

    @Prop({type:String,required:true})
    title!:String

    //Parametros
    search:String="";
    color:String = "Success";

    //Estas son las computed
    UpdateColor(valor:Boolean):String
     {
        if(valor ==true)
        {
            return "primary";
        }
        else
        {
            return "red lighten-1";
        }
    } 
    UpdateTexto(valor:Boolean):String
     {
        if(valor ==true)
        {
            return "Activo";
        }
        else
        {
            return "Inactivo";
        }
    } 
    agregarAnio(item:Hacienda)
    {
        //console.log(item);
        this.$emit('agregarAnio',item);
    }

    agregarLote<T>(item:T)
    {
        //console.log(item);
        this.$emit('agregarLote',item);
    }
      verDetalle<T>(item:T)
    {
        //console.log(item);
        this.$emit('verDetalle',item);
    }
      editarHacienda<T>(item:T)
    {
        //console.log(item);
        this.$emit('editarHacienda',item);
    }
 
}
